import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import '../fragments';

import { localize } from '../utils';
import { DEFAULT_LANG } from '../constants';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const IndexPage = ({ data, lang }) => {
  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }

  const introBlockText = localize(data.sanitySite._rawIntroBlockText, langs);

  return (
    <Layout>
      <SEO langs={langs} />
      <BlockContent className="introduction" blocks={introBlockText} />
    </Layout>
  );
};

IndexPage.defaultProps = {
  lang: DEFAULT_LANG,
};

IndexPage.propTypes = {
  lang: PropTypes.string,
};

export const query = graphql`
  query {
    sanitySite {
      _rawIntroBlockText
    }
  }
`;

export default IndexPage;
